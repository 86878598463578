<template>
  <div id="app">
    <vgl-renderer
      class="renderer"
      camera="camera"
      scene="scene"
      antialias
    >
      <vgl-scene name="scene" background-color="white">
        <vgl-shader-material
          name="std"
          :wireframe="isWireframe"
          :uniform="uniforms"
          :vertexShader="vertexShader"
          :fragmentShader="fragmentShader"
        />
        <mx-click
          name="mx_click"
          :position="mxClickPosVector"
          material="std"
        />
        <mx-down
          name="mx_down"
          :position="mxDownPosVector"
          material="std"
        />
        <mx-pin
          name="mx_pin"
          :position="mxPinPosVector"
          material="std"
        />
        <mx-spring
          name="mx_spring"
          :position="mxSpringPosVector"
          material="std"
        />
        <mx-up
          name="mx_up"
          :position="mxUpPosVector"
          material="std"
        />
        <up-mx
          name="up_mx"
          :position="upMxPosVector"
          material="std"
        />
        <vgl-ambient-light :intensity="intensity" />
        <vgl-directional-light position="0 1 2" />
      </vgl-scene>
      <vgl-perspective-camera
        :orbit-target="orbitTarget"
        :orbit-position="orbitPosition"
        :fov="fov"
        :zoom="zoom"
        name="camera"
      />
    </vgl-renderer>
  </div>
</template>

<script>
import {
  VglRenderer,
  VglPerspectiveCamera,
  VglScene,
  VglAmbientLight,
  VglDirectionalLight,
  VglShaderMaterial,
} from "vue-gl";
import MxClick from "./components/switch/MxClick";
import MxDown from "./components/switch/MxDown";
import MxPin from "./components/switch/MxPin";
import MxSpring from "./components/switch/MxSpring";
import MxUp from "./components/switch/MxUp";
import UpMx from "./components/switch/UpMx";

export default {
  name: "App",
  components: {
    VglRenderer,
    VglPerspectiveCamera,
    VglScene,
    VglAmbientLight,
    VglDirectionalLight,
    VglShaderMaterial,
    MxClick,
    MxDown,
    MxPin,
    MxSpring,
    MxUp,
    UpMx,
  },
  data: () => ({
    fov: 50,
    zoom: 0.5,
    xpos: 0,
    ypos: 100,
    zpos: 0,
    width: 10,
    rotation: 1,
    intensity: 0.2,
    cameraOffset: 0.25,
    targety: 0,
    isWireframe: true,
    uniforms: {
      thickness: {
        value: 1
      }
    },
    vertexShader: `
			attribute vec3 center;
			varying vec3 vCenter;

			void main() {

				vCenter = center;

				gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );

			}
    `,
    fragmentShader: `
    uniform float thickness;

			varying vec3 vCenter;

			void main() {

				vec3 afwidth = fwidth( vCenter.xyz );

				vec3 edge3 = smoothstep( ( thickness - 1.0 ) * afwidth, thickness * afwidth, vCenter.xyz );

				float edge = 1.0 - min( min( edge3.x, edge3.y ), edge3.z );

				gl_FragColor.rgb = gl_FrontFacing ? vec3( 0.1, 0.1, 0.1 ) : vec3( 0.4, 0.4, 0.5 );
				gl_FragColor.a = edge;

			}
    `
  }),
  computed: {
    orbitTarget: function () {
      return `0 ${this.targety} 0`;
    },
    orbitPosition: function () {
      return `20 1 ${this.rotation}`;
    },
    mxClickPosVector: function () {
      return `${this.xpos} ${this.ypos * -0.03} ${this.zpos}`;
    },
    mxDownPosVector: function () {
      return `${this.xpos} ${this.ypos * -0.15} ${this.zpos}`;
    },
    mxPinPosVector: function () {
      return `${this.xpos} ${this.ypos * -0.06} ${this.zpos}`;
    },
    mxSpringPosVector: function () {
      return `${this.xpos} ${this.ypos * -0.05} ${this.zpos}`;
    },
    mxUpPosVector: function () {
      return `${this.xpos} ${this.ypos * 0.05} ${this.zpos}`;
    },
    upMxPosVector: function () {
      return `${this.xpos} ${this.ypos * 0.08} ${this.zpos}`;
    },
  },
  created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll () {
      var h = document.documentElement, 
      b = document.body,
      st = 'scrollTop',
      sh = 'scrollHeight';

      var percent = (1-(h[st]||b[st]) / ((h[sh]||b[sh]) - h.clientHeight)) * 100;
      this.ypos = percent;
    }
  }
};
</script>

<style>
body {
  margin:0;
  padding:0;
  height: 200vh;
}
.renderer {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
}
.renderer iframe {
  float: left;
}
</style>
