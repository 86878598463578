import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import VglLoader from '../../mixins/VglLoader';

const loader = new OBJLoader();

export default {
  mixins: [VglLoader],
  computed: {
    loader() {
      return loader;
    }
  },
};
